import React, { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import { send } from 'emailjs-com';

const ContactForm = () => {
    let navigate = useNavigate()

    const [toSend, setToSend] = useState({
        from_name: '',
        subject: '',
        phone_number: "",
        message: '',
        reply_to: '',
    });

    const [button, setButton] = useState(false);

    useEffect(() => {
        if(toSend.from_name !== "" && toSend.subject !== "" && toSend.message !== "" && toSend.reply_to !== "") {
            setButton(true);
        } else {
            setButton(false)
        }
        console.log(toSend)
    }, [toSend, setButton])
    
    const onSubmit = (e) => {
        e.preventDefault();
        // if(toSend.phone_number === ""){
        //     setToSend({...toSend, phone_number: "NOT PROVIDED"})
        // }]
        setButton(false)
        if(toSend.from_name !== "" && toSend.subject !== "" && toSend.message !== "" && toSend.reply_to !== "") {
                send(
                    'service_998mhgj',
                    'template_cmdeudf',
                    toSend,
                    'J0BL3mUhohNeQXatU'
                )
                    .then((response) => {
                        console.log('SUCCESS!', response.status, response.text);
                    })
                    .then(() => {
                        setToSend({
                            from_name: '',
                            subject: '',
                            phone_number: "",
                            message: '',
                            reply_to: '',
                        })
                        navigate('/submitted')
                    })
                    .catch((err) => {
                        console.log('FAILED...', err);
                    });
        }
        setButton(false)
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

  return (
    <div className='contactFormSection'>
        <h1 className="contactFormTitle">CONTACT US</h1>
        <p className="contactFormRequired">* is required</p>
        <form className='contactForm'>
            <div className="contactFormTop">
                <div className="contactFormInput">
                    <div className="contactFormInputTop">
                        <p className="contactFormLabel">Name:</p>
                        <p className='contactFormStar'>*</p>
                    </div>
                    <input name='from_name' value={toSend.from_name} onChange={handleChange} type="text" />
                </div>
                <div className="contactFormInput">
                    <div className="contactFormInputTop">
                        <p className="contactFormLabel">Email:</p>
                        <p className='contactFormStar'>*</p>
                    </div>
                    <input name='reply_to' value={toSend.reply_to} onChange={handleChange} type="text" />
                </div>
            </div>
            <div className="contactFormBottom">
                <div className="contactFormInput">
                    <div className="contactFormInputTop">
                        <p className="contactFormLabel">Phone number:</p>
                        <p className='contactFormStar'></p>
                    </div>
                    <input name='phone_number' value={toSend.phone_number} onChange={handleChange} type="text" />
                </div>
                <div className="contactFormInput">
                    <div className="contactFormInputTop">
                        <p className="contactFormLabel">Subject:</p>
                        <p className='contactFormStar'>*</p>
                    </div>
                    <input name='subject' value={toSend.subject} onChange={handleChange} type="text" />
                </div>
            </div>
            <div className="contactFormTextareaTop">
                <p className="contactFormLabel">Message:</p>
                <p className='contactFormStar'>*</p>
            </div>
            <textarea name='message' value={toSend.message} onChange={handleChange} className='contactFormTextarea'></textarea>
        </form>
        <div className="contactFormBtnContainer">
            <button onClick={onSubmit} disabled={!button} className={`contactFormBtn ${button ? "contactFormBtnShow" : ""}`}>SUBMIT</button>
        </div>
    </div>
  )
}

export default ContactForm